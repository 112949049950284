export class General {

  static open(url: string) { window.open(url); }

  static getHref() { return window.location.href; }

  static scrollTo(num1, num2) { window.scrollTo(num1, num2); }

  static scroll(num1, num2) { window.scroll(num1, num2); }

  static dispatchEvent(event) { window.dispatchEvent(event); }
}
