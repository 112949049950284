// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  serverUrlAdmin: 'https://testadminapi.passer.cr/',
  serverUrlPasser: 'https://testapi.passer.cr/',
  serverUrlAWS: 'https://p7pbypv7y4.execute-api.us-west-2.amazonaws.com/default',// https://n8n.passer.cr/webhook/893240aa-e9e5-4af3-a80d-535522ab5af4',
  catalogUrlPasser: 'https://testcatalogs.passer.cr/',
  enterprises: 'https://testenterprises.passer.cr/',
  dashboardAccount: 'https://testdea.passer.live/',
  idpUrlPasser: "https://testidp.passer.live",
  idpClientId: "dae",
  idpClientSecret: "bar",
  idpRedirectUri: "https://oidcdebugger.com/debug",
  idpResource: "https://enterprises.passer.cr",
  API_KEY_MAPS: 'AIzaSyAaQclndPTJ6cKQIZyaVLT-z49Xzdgl8a4'
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
