import {NgModule} from '@angular/core';
import {MatNativeDateModule} from '@angular/material/core';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatDialogModule} from '@angular/material/dialog';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatChipsModule} from '@angular/material/chips';
import {MatIconModule} from '@angular/material/icon';
import {MatTooltipModule} from '@angular/material/tooltip';
import {ReactiveFormsModule, FormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import { MatRadioModule } from '@angular/material/radio';

import { MatButtonModule } from '@angular/material/button';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSelectModule } from '@angular/material/select';
import { MatStepperModule } from '@angular/material/stepper';

@NgModule({
  imports: [
    ReactiveFormsModule, FormsModule, CommonModule, MatProgressSpinnerModule, MatChipsModule,
    MatDialogModule, MatDatepickerModule, MatFormFieldModule, MatInputModule, MatNativeDateModule,
    MatIconModule, MatTooltipModule,MatRadioModule, MatButtonModule, MatProgressBarModule,
    MatCardModule, MatCheckboxModule, MatSelectModule, MatStepperModule
  ],
  exports: [
    ReactiveFormsModule, FormsModule, CommonModule, MatProgressSpinnerModule, MatChipsModule,
    MatDialogModule, MatDatepickerModule, MatFormFieldModule, MatInputModule, MatNativeDateModule,
    MatIconModule, MatTooltipModule, MatRadioModule, MatButtonModule, MatProgressBarModule,
    MatCardModule, MatCheckboxModule, MatSelectModule, MatStepperModule
  ]
})
export class AppMaterialModule {}
