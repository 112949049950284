import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { CardComponent } from '../components/card/card.component';
import { PresenterComponent } from 'src/components/presenter/presenter.component';
import { BoxedContainerComponent } from 'src/components/boxed-container/boxed-container.component';
import { AlertSelectorComponent } from 'src/components/alert-selector/alert-selector.component';
import { AppMaterialModule } from './app.material.module';
import { RoundedImageSelectorComponent } from 'src/components/rounded-image-selector/rounded-image-selector.component';
import { ErrorMessageComponent } from 'src/components/error-message/error-message.component';
import { TranslateModule } from '@ngx-translate/core';


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        AppMaterialModule,
        TranslateModule,
        AppMaterialModule
    ],
    declarations: [
      CardComponent, PresenterComponent, BoxedContainerComponent, AlertSelectorComponent, RoundedImageSelectorComponent, ErrorMessageComponent
    ],
    providers: [
    ],
    exports: [
      CardComponent, PresenterComponent, BoxedContainerComponent, AlertSelectorComponent, RoundedImageSelectorComponent, ErrorMessageComponent
    ]
})
export class SharedModule {}
